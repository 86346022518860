import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import HomeScreen from './HomeScreen';
import PortfolioAnalysisScreen from './PortfolioAnalysisScreen';
import { useEffect, useState } from 'react';

function NavBarCustom() {
  // const location = useLocation();
  // useEffect(() => {
  //   console.log('Location changed');
  //   // change the navbar view state here
  // }, [location]);
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      {['md'].map((expand) => (
        <Navbar key={expand} expand={expand} expanded={expanded} className="bg-body-tertiary mb-3">
          <Container fluid>
            <Navbar.Brand href="#">
            <img
                src="/nav.png"
                width="110"
                height="40"
                className="d-inline-block align-top"
                alt=""
              />
              {''}</Navbar.Brand>
            <Navbar.Toggle 
            onClick={() => setExpanded(!expanded)}
            aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              onHide = {() => setExpanded(false)}
            >
              <Offcanvas.Header closeButton >
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Mutual Fund Analytics
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link><Link 
                              onClick={() => setExpanded(false)}
                              to='/' className=' text-gray-500 no-underline'>Home</Link></Nav.Link>
                  <Nav.Link><Link 
                            onClick={() => setExpanded(false)}
                            to='PortfolioAnalysis' className=' text-gray-500 no-underline'>Portfolio Analysis</Link></Nav.Link>
                  <Nav.Link><Link 
                            onClick={() => setExpanded(false)}
                            to='allFunds' className=' text-gray-500 no-underline'>All-Funds</Link></Nav.Link>
                  <NavDropdown
                    className=' text-gray-500 '
                    title="Explore"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item href="/TermsOfUse">Terms and Conditions</NavDropdown.Item>
                    <NavDropdown.Item href="/PrivacyPolicy">
                      Privacy Policy
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/login">
                      Login
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
                <Form className="d-flex">
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                  />
                  <Button variant="outline-success">Search</Button>
                </Form>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
        
      ))}
    </>
  );
}

export default NavBarCustom;