import React from 'react'
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import debounce from 'lodash.debounce';
import { createClient } from '@supabase/supabase-js';
import CategoryScrollCards from './CategoryScrollCards';
import Button from 'react-bootstrap/Button';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import FundCards from './FundCards';
import * as XLSX from 'xlsx';

export default function FullListFilters() {

  const supabaseUrl = 'https://nahsjrrpqsjysmlyweay.supabase.co';
  const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
  const supabase = createClient(supabaseUrl, supabaseKey);

  const [fundList, setFundList] = useState([]);
  const [selectedFunds, setSelectedFunds] = useState([]);
  const [sortBy, setSortBy] = useState('return_3yr');
  const [yrs, setYrs] = useState(3);
  const [name, setName] = useState('Return');
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  // Create a ref for the element you want to scroll to
  const resultsSectionRef = useRef(null);

  const scrollToBottom = () => {
    if (resultsSectionRef.current) {
      resultsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {

    const fetchCategories = async () => {
      const { data, error } = await supabase
        .from('all_funds_basic')
        .select('`Scheme Category`', { distinct: true })
        .neq('Scheme Category', null)
      // .distinct();
      if (error) {
        console.error('Error fetching categories:', error.message);
        return;
      }

      // const uniqueCategories = data.map(item => item['Scheme Category']);
      // Deduplicate in JavaScript as a fallback
      const uniqueCategories = [...new Set(data.map(item => item['Scheme Category']))];
      setCategories(uniqueCategories);
    };

    const fetchFundList = async () => {

      // const { data, error } = await supabase
      //   .from('all_funds_basic')
      //   .select('`Scheme Code`,`Scheme Name`,`Scheme Category` ,`Scheme Name Nav`, `return_3yr_perc`,`return_1yr`,`return_5yr`,`return_3yr`,`return_1yr_perc`,`return_5yr_perc`,`sortino_ratio_12_perc`,`sortino_ratio_36_perc`,`sortino_ratio_60_perc`,`sortino_ratio_12`,`sortino_ratio_36`,`sortino_ratio_60`')
      //   .eq('reg-direct', 'direct')
      //   .eq('grow-div', 'growth')
      //   .lt(`${sortBy}`, 3)
      //   .order(sortBy, { ascending: false })
      //   .range(0, 20);

      let query = supabase
        .from('all_funds_basic')
        .select('`Scheme Code`, `Scheme Name`, `Scheme Category`, `return_3yr_perc`, `return_1yr_perc`, `return_5yr_perc`, `sortino_ratio_12`, `sortino_ratio_36`, `sortino_ratio_60`')
        .eq('reg-direct', 'direct')
        .eq('grow-div', 'growth')
        .lt(sortBy, 10000000)
        .order(sortBy, { ascending: false });

      if (selectedCategory) {
        query = supabase
        .from('all_funds_basic')
        .select('`Scheme Code`, `Scheme Name`, `Scheme Category`, `return_3yr_perc`, `return_1yr_perc`, `return_5yr_perc`, `sortino_ratio_12`, `sortino_ratio_36`, `sortino_ratio_60`')
        .eq('reg-direct', 'direct')
        .eq('grow-div', 'growth')
        .eq('Scheme Category', selectedCategory)
        .lt(sortBy, 10000000)
        .order(sortBy, { ascending: false });
        
      }

      const { data, error } = await query.range(0, 50);

      if (error) {
        console.error('Error fetching fund list:', error.message);
        return;
      }

      // Filter out funds where category includes "debt" and return > 20%
      const filtered = data.filter((fund) => {
        const category = fund["Scheme Category"]?.toLowerCase();
        const return3Yr = parseFloat(fund["return_3yr_perc"]) || 0;
        const return1Yr = parseFloat(fund["return_1yr_perc"]) || 0;
        const return5Yr = parseFloat(fund["return_5yr_perc"]) || 0;

        return (
          !(category.includes("debt") &&
          (return3Yr > .20 || return1Yr > .20 || return5Yr > .20))
        );
      });

      setFundList(filtered);
    };

    const fetchData = async () => {
      await fetchFundList();
      await fetchCategories();
      // await analyzeFunds();
    };

    fetchData();


    // Updated code to use resultsSectionRef
    if (resultsSectionRef.current) {
      scrollToBottom();
    }
    // if (fundsAnalysed) {
    //   // Scroll to the results section when funds are analyzed
    //   scrollToResultsSection();
    // }
  }, [sortBy, selectedCategory]);

  const handleSortChange = (value) => {
    let yrs = null
    let name = null
    if (value.includes('sortino')) {
      // Extract the last two characters
      var lastTwoCharacters = value.substring(value.length - 2);

      // Convert the substring to an integer
      var resultAsInteger = parseInt(lastTwoCharacters);
      yrs = resultAsInteger / 12
      name = 'Sortino'
    }
    else {

      yrs = parseInt(value.charAt(value.length - 3))
      name = 'Return'
    }
    setName(name);
    setYrs(yrs);
    setSortBy(value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };



  return (
    <div>

      <Row className=" d-flex justify-content-between mb-4">
        <Col xs={5}>
          <label className="text-gray-500">Category:</label>
          <select className="form-select" value={selectedCategory} onChange={handleCategoryChange}>
            <option value="">All Categories</option>
            {categories.map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))}
          </select>
        </Col>

        <Col xs={5} >
          <label className="text-gray-500">Sort by:</label>
          <select className="form-select" value={sortBy} onChange={(e) => handleSortChange(e.target.value)}>
            <option value="return_3yr">Annualized Returns 3yr</option>
            <option value="return_1yr">Annualized Returns 1yr</option>
            <option value="return_5yr">Annualized Returns 5yr</option>
            <option value="sortino_ratio_36">Risk Adjusted Returns 3yr</option>
            <option value="sortino_ratio_12">Risk Adjusted Returns 1yr</option>
            <option value="sortino_ratio_60">Risk Adjusted Returns 5yr</option>
          </select>
        </Col>

        {/* <Col sx={7} className='text-left mb-4 text-gray-500'>
          Sort by:
        </Col>
        <Col xs={7} className='text-right mb-4 mr-2 text-gray-500'>
          <select className='' value={sortBy} onChange={(e) => handleSortChange(e.target.value)}>
            <option value="return_3yr">Annualized Returns 3yr</option>
            <option value="return_1yr">Annualized Returns 1yr</option>
            <option value="return_5yr">Annullized Returns 5yr</option>
            <option value="sortino_ratio_36">Risk Adjusted Returns 3yr</option>
            <option value="sortino_ratio_12">Risk Adjusted Returns 1yr</option>
            <option value="sortino_ratio_60">Risk Adjusted Returns 5yr</option>
          </select>
        </Col> */}
      </Row>

      {fundList.map((fund, index) => (
        <Col key={fund['Scheme Code']} className="mb-3">
        <Link
          to={`/amc/${fund['Scheme Code']}`}
          style={{ textDecoration: 'none', cursor: 'pointer' }}
        >
        <Card key={index} className="mt-3" style={{ height: '100%', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', border: 'none', overflow: 'auto' }}>
          <Card.Body>
            <Card.Text className='text-sm font-semibold'>{fund['Scheme Name']}</Card.Text>
            <table className='table-auto mt-3 mb-3 mx-auto border-collapse sm:table md:table-none'>
              <tbody>
                <tr className='sm:flex md:table-row'>
                  <td className='sm:w-1/2 border-gray-200 px-4 py-2 text-sm text-center font-semibold'>Category :   <text className='text-blue-500 mx-1'>{fund['Scheme Category']}</text></td>
                  {/* <td className='sm:w-1/2 border-gray-200 text-blue-500 px-4 py-2 text-sm text-right'>{fund['Scheme Category']}</td> */}
                </tr>
                <tr className='sm:flex md:table-row'>
                  <td className='sm:w-1/2 border-gray-200 px-4 py-2 text-sm text-center font-semibold'>{yrs} year Returns :   <text className='text-blue-500 mx-2'>{sortBy.includes('sortino') ? <normal className= ''>{typeof fund[sortBy] === 'number' ? (fund[sortBy]).toFixed(2) : fund[sortBy]}</normal> : <normal className= ''>{(fund[sortBy] * 100).toFixed(1) + '%'}</normal>}</text></td>
                  {/* <td className='sm:w-1/2 border-gray-200 text-blue-500 px-4 py-2 text-sm text-right'>{fund[sortBy]}</td> */}
                </tr>
              </tbody>
            </table>
          </Card.Body>
        </Card>
        </Link>
        </Col>
      ))}
    </div>
  )
}
